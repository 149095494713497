/** @jsx jsx */
import { jsx } from 'theme-ui';

import Layout from '~/components/Layout';
import AccountPage from '~/components/Pages/Account/';
import Metadata from '~/components/Metadata';

const Account = () => {
  return (
    <Layout hideWidget>
      <Metadata />
      <AccountPage />
    </Layout>
  );
};

export default Account;
